import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import SimpleHeader from "../components/SimpleHeader";
import UserTable from "../components/settings/UserTable";
import AccountTab from "../components/settings/AccountTab";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"; // Updated import here
import { useAuth0 } from "@auth0/auth0-react";
import MainAppWrap from "../layout/MainAppWrap";
import Channels from "../components/settings/Channels";
import KsConfigurations from "../components/settings/KnowledgeSourceConfigurations";
import KnowledgeSource from "../components/settings/KnowledgeSource";
import Billing from "../components/settings/Billing";
import MainLoader from "../components/common/MainLoader";
import { ToastContainer } from "react-toastify";

function Settings() {
  const { tab } = useParams(); // Using the URL parameter
  const [key, setKey] = useState("account-management");
  const { isLoading, user, isAuthenticated, loginWithRedirect } = useAuth0();
  const groups = user?.ept_roles || [];
  const [isMounted, setIsMounted] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Settings tab:");
    // Check if 'tab' is undefined or empty and redirect to the default tab
    if (!tab) {
      navigate(`/settings/account-management`, { replace: true });
    } else {
      // Update 'key' based on the 'tab' URL parameter
      setKey(tab);
    }
  }, [tab, navigate]);
  

  const updateSearchParamAndTab = (newKey) => {
    // This function now just updates the URL parameter instead of the search query
    navigate(`/settings/${newKey}`);
    setKey(newKey);
  };

  useEffect(() => {
    if (groups.length > 0 && groups[0] !== "Admin") {
      // Redirect or show a message based on the user's permission
      navigate("/"); // Example redirect, modify as needed
    }
  }, [groups, navigate]);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  if (isLoading) {
    return (
      <MainLoader />
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  if (!isMounted) {
    return null;
  }

  return (
    <MainAppWrap>
      <div className="main-content">
        <SimpleHeader />
        <Container fluid="xxl">
          <Row>
            <Col className="settings-header">
            {key === "account-management" && <h1>Account Management</h1>}
            {key === "channels" && <h1>Channels</h1>}
            {key === "knowledge-sources" && <h1>Knowledge Sources</h1>}
            {key === "knowledge-source-configurations" && <h1>Knowledge Source Configurations</h1>}
            {key === "user-management" && <h1>Users</h1>}
            {key === "billing" && <h1>Billing</h1>}

            </Col>
          </Row>
          <Row>
            <Col>
              {groups.length > 0 && groups[0] === "Admin" ? (
                <Tabs
                  id="user-management-tabs"
                  activeKey={key}
                  onSelect={(k) => updateSearchParamAndTab(k)}
                  className="mb-3 user-management-tabs"
                >
                  <Tab eventKey="account-management" title="Account">
                    <AccountTab urlParam={key} />
                  </Tab>
                  <Tab eventKey="channels" title="Channels">
                    <Channels urlParam={key} />
                  </Tab>

                  <Tab eventKey="knowledge-sources" title="Knowledge Sources">
                    <KnowledgeSource urlParam={key} />
                  </Tab>

                  <Tab eventKey="knowledge-source-configurations" title="Knowledge Source Configurations"> 
                    <KsConfigurations urlParam={key} />
                  </Tab>


                  <Tab eventKey="user-management" title="Users">
                    <UserTable urlParam={key} />
                  </Tab>

                  <Tab eventKey="billing" title="Billing">
                    <Billing urlParam={key} />
                  </Tab>
                </Tabs>
              ) : (
                <div>
                  <p>You don't have permission to access this page.</p>
                  <p>
                    Go back to <Link to="/">main</Link>
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </MainAppWrap>
  );
}

export default Settings;
