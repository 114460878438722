import React from 'react';
import ReactDOM from 'react-dom';
// import {Amplify} from 'aws-amplify';
// import config from './aws-exports';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';

// Amplify.configure(config);  
  
ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);



