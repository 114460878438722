import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useForumList } from "../common/ForumListContext";
import { apiPath } from "../../config";

function HeroFilter(props) {
  const { forumIds, selectedForumId, setSelectedForumId, setSelectForumName } = useForumList();
  const { user, getIdTokenClaims } = useAuth0();
  
  const [searchQ, setSearchQ] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [searchingLoader, setSearchingLoader] = useState(false);

  const handleSidebarNav = (e, forumId, forumName) => {
    e.preventDefault(); // This might not be needed anymore since select dropdowns don't have a default action like <a> tags, but it's okay to leave it if you're handling other form elements.
    setSelectedForumId(forumId);
    setSelectForumName(forumName);
};
  useEffect(() => {
    // console.log('Updated selectedForumId:', selectedForumId);
    // Perform any additional actions here
  }, [selectedForumId]);


  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearchQ(newValue);

    // Clear the previous typing timeout
    clearTimeout(typingTimeout);

    if (newValue === "") {
      setSearchResults([]);
      return;
    } // Set a new timeout
    const newTimeout = setTimeout(() => {
      getThreadSummery(0, 20, false, null, false, null, null, newValue);
    }, 1000); // Adjust the delay as needed

    setTypingTimeout(newTimeout);
  };

  const handleSearchFocus = () => {
    setShowResults(true);
  };

  const handleSearchBlur = () => {
    setTimeout(() => {
      setShowResults(false);
    },150);
  };

  useEffect(() => {
    if(props.selectedForumId){
      setSearchQ("");
     setSearchResults([]);
    }
  }, [props.selectedForumId]);

  const getThreadSummery = async (offset, itemCount, solved, sort_by, unresponded, tags, boards, q) => {
    try {
       // Get the ID token from Auth0
       const getidToken = await getIdTokenClaims();
       const idToken = getidToken.__raw;
          console.log('idToken-', idToken);
     
       const options = {
         method: 'GET',
         headers: {
           Authorization: `Bearer ${idToken}`,
           'Content-Type': 'application/json'
         },
       };
       console.log("in search")
      setSearchingLoader(true);
      // Start with the base path
      let getPath = `/thread_summary?forum_id=${props.selectedForumId}&offset=${offset}&item_count=${itemCount}`;

      // Conditionally add parameters
      if (solved) {
        getPath += `&solved=${solved}`;
      }

      if (sort_by && sort_by !== null) {
        getPath += `&sort_by=${sort_by}`;
      }

      if (unresponded) {
        getPath += `&unresponded=${unresponded}`;
      }

      if (tags && tags.length > 0) {
        const tagsArray = tags.map(item => item.value);
        getPath += `&tags=${encodeURIComponent(JSON.stringify(tagsArray))}`;
      }

      if (boards && boards.length > 0) {
        const boardsArray = boards.map(item => item.value);
        getPath += `&boards=${encodeURIComponent(JSON.stringify(boardsArray))}`;
      }

      if (q && q !== null) {
        const encodedQ = encodeURIComponent(q);
        getPath += `&q=${encodedQ}`;
      }
      const url = `${apiPath()}${getPath}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const getResult = await response.json();
      // console.log(getResult);
      if (getResult.threads.length > 0) {
        setSearchResults(getResult.threads);
      } else {
        setSearchResults([]);
      }
      setSearchingLoader(false);
    } catch (error) {
      console.error("Error thread summary:", error);
    }
  };

  const handleResultClick = (id) => {
    props.setThreadId(id);
    // props.setSwitchlisttoThread(true);
    setSearchQ("");
    setSearchResults([]);
  };

  

  return (
    <div className="hero-filter">
      <div className="hero-text">
          {props.selectForumName && (
              <div className="forumName desktop-only">
                <span>{props.selectForumName.replace(/(http|https|s:|\/)/g, '')}</span>
              </div>
            )}
            <select
              className="forum-dropdown-mobile mobile-only"
              value={selectedForumId}
              onChange={(e) => {
                  const selectedOption = forumIds.find(forum => forum.value === e.target.value);
                  handleSidebarNav(e, e.target.value, selectedOption ? selectedOption.label : '');
              }}
          >
              {forumIds && forumIds.map((forum) => (
                  <option key={forum.value} value={forum.value}>
                      {forum.label.replace(/(http|https|s:|\/)/g, '')}
                  </option>
              ))}
          </select>
            <p className="forumHint">Choose a thread from your community forum to see an AI-generated draft response</p>
          </div>
      <div>
        <div className="hero-filters-wrap">
          <div className="searchWrap">
            <input
              type="text"
              className="hero-search"
              placeholder="Search thread titles"
              onChange={handleSearchChange}
              value={searchQ}
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
            />
            {searchingLoader && (
              <div className="searchLoader">
                  <div className="searchLoaderInner">
                  <div className="chat-loader">
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </div>
                  </div>
              </div>
            )}
            {showResults && (
              <div className="searchResults">
                <ul>
                  {searchResults &&
                    searchResults.map((thread, index) => (
                      <li
                        key={index}
                        onClick={() => handleResultClick(thread.thread_id)}
                      >
                        {thread.title}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>

          {/* <select
            className="forum-select"
            id="forumID"
            onChange={props.handleForumChange}
            value={props.selectedForumId}
          >
            {props.forumIds.map((forumId) => (
              <option key={forumId.value} value={forumId.value}>
                {forumId.label}
              </option>
            ))}
          </select> */}
        </div>
      </div>
    </div>
  );
}

export default HeroFilter;
