import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainAppWrap from "../layout/MainAppWrap";
import SimpleHeader from "../components/SimpleHeader";
import { useAuth0 } from "@auth0/auth0-react";
import { apiPath } from "../config";
import ActivitiesChart from "../components/reporting/ActivitiesChart";
import ResponseCountChart from "../components/reporting/ResponseCountChart";
import UserFeedbackChart from "../components/reporting/UserFeedbackChart";
import Select from "react-select";
import ReportingFilter from "../components/reporting/ReportingFilter";


function Reporting() {
    const { user, getIdTokenClaims } = useAuth0();
    const [channels, setChannels] = useState([]);
    const [channel_list, setChannelList] = useState([]);
    const [filterChannels, setFilterChannels] = useState("");
    const [start_date, setStartDate] = useState(null);
    const [end_date, setEndDate] = useState(null);
    const [range, setRange] = useState(30);
    const [loading, setLoading] = useState(false);



    const getReports = async (type, lookback, series, channel, startDate, endDate) => {
       try{
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const options = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
            },
        };

        let parameters = "";

        if(type){
            parameters += `data_type=${encodeURIComponent(type)}`;
        }
        if(lookback && !startDate && !endDate){
            parameters += `&lookback=${encodeURIComponent(lookback)}`;
        }
        if(series){
            parameters += `&series_type=${encodeURIComponent(series)}`;
        }
        if(channel){
            console.log("in function- ", channel);
            parameters += `&channel_id_list=${encodeURIComponent(channel)}`;
        }

        if(startDate){
            parameters += `&start_date=${encodeURIComponent(startDate)}`;
        }
        if(endDate){
            parameters += `&end_date=${encodeURIComponent(endDate)}`;
        }
        const path = `/usage?${parameters}`;
        const url = `${apiPath()}${path}`;
        const response = await fetch(url, options);
        const data = await response.json();
        return data;
        console.log(data);
       } catch (error) {
        console.error(error);
       }
    }
    const fetchChannels = async () => {
        try {
          // Get the ID token from Auth0
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
           const options = {
             method: 'GET',
             headers: {
               Authorization: `Bearer ${idToken}`,
               'Content-Type': 'application/json'
             },
           };
          // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
          const path = `/channel_list`;
          const url = `${apiPath()}${path}`;
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
          //  console.log(result);
           setChannelList(result);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      useEffect(() => {
          if(channel_list.length === 0 && user){
            fetchChannels();
          }
      }, [user]);
      const handleChannelChange = (selectedOptions) => {
        // console.log(selectedOptions);
        setChannels(selectedOptions);
        const options = selectedOptions.map((channel) => channel.value).join(",");
        setFilterChannels(options);
      };
  return (
    <MainAppWrap>
      <div className="main-content">
        <SimpleHeader title="Reporting" />
        <Container fluid="xxl">
          <div className="reporting-page">
            <Row>
              <Col>
              <div className="response-filters reporting-filters">
                <div className="filters-input">
                <Select
                        id="select-channels"
                        onChange={handleChannelChange}
                        value={channels}
                        options={channel_list.map((channel, index) => ({
                        value: channel.channel,
                        label: (channel.channel +" - " +channel.name),
                        }))}
                        isSearchable
                        placeholder="Select Channels"
                        isMulti
                    />
                </div>
                <div>
                <ReportingFilter 
                    getReports={getReports}
                    range={range}
                    setRange={setRange}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    start_date={start_date}
                    end_date={end_date}
                    loading={loading}
                    />
                </div>
                
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <ResponseCountChart 
                    getReports={getReports}
                    channel={filterChannels}
                    start_date={start_date}
                    end_date={end_date}
                    range={range}
                    setLoading={setLoading}
                />
              </Col>
            </Row>
            <Row>
                <Col>
                    <ActivitiesChart 
                        getReports={getReports}
                        channel={filterChannels}
                        start_date={start_date}
                        end_date={end_date}
                        range={range}
                        setLoading={setLoading}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <UserFeedbackChart 
                        getReports={getReports}
                        channel={filterChannels}
                        start_date={start_date}
                        end_date={end_date}
                        range={range}
                        setLoading={setLoading}
                    />
                </Col>
            </Row>
          </div>
        </Container>
      </div>
    </MainAppWrap>
  );
}

export default Reporting;
