import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Table from "react-bootstrap/Table";
import SettingsLoader from "../../common/SettingsLoader";
import { useParams } from "react-router-dom";
import like_icon from "../../../images/like.svg";
import dislike_icon from "../../../images/dislike.svg";
import like_source from "../../../images/like_source.svg";
import dislike_source from "../../../images/dislike_source.svg";
import { apiPath } from "../../../config";

function Sources(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("query") || "");
  const [items, setItems] = useState(props.sources || []);
  const[searchableItems, setSearchableItems] = useState([]);
  const { getIdTokenClaims, user } = useAuth0();
  const [userGroups, setUserGroups] = useState("");

  useEffect(() => {
    props.sources && setItems(props.sources);
    props.sources && setSearchableItems(props.sources);
  }, [props.sources]);

  useEffect(() => {
    if (user) {
      const roles = user?.ept_roles;
      if (roles && roles.includes("Admin")) {
        setUserGroups("Admin");
        // console.log("Admin role");
      }
    }
  }, [user]);
  
  useEffect(() => {
        
    // filter items from setSearchableItems based on the keys in the query switch case below
    let filteredItems = searchableItems;
    if (query) {
        filteredItems = filteredItems.filter((item) => {
            const queryLower = query.toLowerCase();
            return item.title.toLowerCase().includes(queryLower) || item.url.toLowerCase().includes(queryLower);
        }
        );
    }
    setItems(filteredItems);        
    
}, [query, items, searchableItems]); // Dependencies on internal state

  useEffect(() => {
    // Sync state with URL search parameters
    setQuery(searchParams.get("query") || "");
  }, [searchParams]);
  let searchTimeout; // Rename the variable to avoid conflict with the clearTimeout function

  const handleSearch = (e) => {
    e.preventDefault();
    const newQuery = e.target.value;

    // Clear any existing timeout to prevent multiple search triggers
    if (searchTimeout) clearTimeout(searchTimeout);

    // Set a new timeout to delay setting search params
    searchTimeout = setTimeout(() => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        query: newQuery,
      });
    }, 500);
  };
  const handleActionButtons = (e) => {
    e.preventDefault();
    let ele = e.target;
    let action_btns = ele.nextElementSibling;

    // Check if the display property is either "none" or not set (empty string).
    if (action_btns.style.display === "block") {
      action_btns.style.display = "none";
    } else {
      // If it's "none" or empty, set it to "block"
      action_btns.style.display = "block";
    }
  };

  document.addEventListener("click", function (e) {
    //   e.preventDefault();
    if (e.target.className !== "action_btn_dots") {
      let action_btns = document.querySelectorAll(".action_btns");
      action_btns.forEach(function (ele) {
        ele.style.display = "none";
      });
    }
  });

  const handleActions = (e) => {
    e.preventDefault();
    let ele = e.target;
    let action = ele.getAttribute("data-action");
    let item_url = ele.getAttribute("data-item-url");
    let ks_id = ele.getAttribute("data-ks-id");
    const mainEle = ele.closest("tr").getAttribute("data-item-id");
    // let action_btns = ele.closest('.action_btns');
    // let action_btn_dots = action_btns.previousElementSibling;
    // action_btns.style.display = 'none';
    // action_btn_dots.style.display = 'block';
    if (action == "boost") {
      // boost item
      console.log("boost item");
      handleActionsAsync(item_url, "boosted", ks_id, mainEle);
    } else if (action == "unboost") {
      // unboost item
      console.log("unboost item");
      handleActionsAsync(item_url, "", ks_id, mainEle);
    } else if (action == "block") {
      // block item
      console.log("block item");
      handleActionsAsync(item_url, "blocked", ks_id, mainEle);
    } else if (action == "unblock") {
      // unblock item
      console.log("unblock item");
      handleActionsAsync(item_url, "", ks_id, mainEle);
    }
  };

  const handleActionsAsync = async (url, reqType, ks_id, mainEle) => {
    try {
      const idToken = await getIdTokenClaims();
      const bodyParse = {
        tags: [reqType],
        url: url,
      };
      const parseJson = JSON.stringify(bodyParse);
      const path = `/knowledge_source/${ks_id}/tags`;
      const response = await fetch(`${apiPath()}${path}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken.__raw}`,
          "Content-Type": "application/json",
        },
        body: parseJson,
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      console.log(data);
      if(data.message === "ok"){
        const element = document.querySelector(`tr[data-item-id="${mainEle}"]`);
        if(reqType === "boosted"){
          element.querySelector(".table-boosted-td").innerHTML = "<span class='boosted'>Boosted</span>";
          element.querySelector(".action_btns").classList.add("show-unboosted");
          element.querySelector(".action_btns").classList.remove("show-unblocked");
        }
        else if(reqType === "blocked"){
          element.querySelector(".table-boosted-td").innerHTML = "<span class='blocked'>Blocked</span>";
          element.querySelector(".action_btns").classList.add("show-unblocked");
          element.querySelector(".action_btns").classList.remove("show-unboosted");
        }
        else{
          element.querySelector(".table-boosted-td").innerHTML = "";
          element.querySelector(".action_btns").classList.remove("show-unboosted");
          element.querySelector(".action_btns").classList.remove("show-unblocked");
        }
      }
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      // setIsLoadingItems(false);
    }
  };

  const handleRanking = async (e) => {
    e.preventDefault();
    let ele = e.target;
    let value = ele.getAttribute("data-value");
    let response_id = ele.getAttribute("data-id");
    let url = ele.getAttribute("data-url");
    // url = encodeURIComponent(url);
    // console.log("value-", value, "response_id-", response_id, "url-", url)
    if(value === "like"){
      const likes = await props.sendFeedback(response_id, true, url);
      if(likes){
        if(likes.likes > 0){
          ele.closest("td").querySelector(".item-likes").innerHTML = likes.likes;
          ele.closest("td").querySelector(".item-dislikes").innerHTML = likes.dislikes;
          if(likes.user_feedback === "like"){
            ele.closest("td").querySelector(".table-like-img").src = like_source;
            ele.closest("td").querySelector(".table-dislike-img").src = dislike_icon;
          }
        }
      }
    }else {
      const dislikes = await props.sendFeedback(response_id, false, url);
      if(dislikes){
        if(dislikes.dislikes > 0){
          ele.closest("td").querySelector(".item-dislikes").innerHTML = dislikes.dislikes;
          ele.closest("td").querySelector(".item-likes").innerHTML = dislikes.likes;
          if(dislikes.user_feedback === "dislike"){
            ele.closest("td").querySelector(".table-dislike-img").src = dislike_source;
            ele.closest("td").querySelector(".table-like-img").src = like_icon;
          }
        }
      }
    }
  }
  return (
    <div className="sources-wrap">
      <div className="item-filters">
        <div className="filters-title">Answer sources</div>
        <div className="filters-inputs">
          <input
            className="item-search"
            type="text"
            placeholder="Search"
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="table-box items-box-table">
        <Table hover>
          <thead>
            <tr>
              <th className="table-name-th">Name</th>
              <th>Type</th>
              <th></th>
              <th>Uses</th>
              <th>Ks ID</th>
              <th>URL</th>
              <th></th>
              <th className="table_action">Action</th>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((item, index) => (
                <tr key={index} data-item-id={item.id}>
                  <td className="table-name-td">{item.title}</td>
                  <td>{item.ks_type}</td>
                  <td className="table-boosted-td">
                    {item.tags && item.tags.includes("boosted") && (
                      <span className="boosted">Boosted</span>
                    )}
                    {item.tags && item.tags.includes("blocked") && (
                      <span className="blocked">Blocked</span>
                    )}
                  </td>
                  <td>{item.uses}</td>
                  <td>
                    <Link to={`/settings/ks-details/${item.ks_id}`}>
                      {item.ks_id} {item.ks_name}
                    </Link>
                  </td>
                  <td className="table-url-td">
                    <Link to={item.url} target="_blank">
                      {item.url}
                    </Link>
                  </td>
                  <td className="table-reviews-td">
                    <img onClick={handleRanking} 
                    className="table-like-img"
                    data-id={props.response_id} 
                    data-value='like' 
                    data-url={item.url} 
                    src={item.user_feedback === 'like' ? like_source : like_icon} alt="like icon" />{" "}
                    {item.likes > 0 ? (<span className="item-likes">{item.likes}</span>) : (<span className="item-likes"></span>)}
                    <img
                     onClick={handleRanking} 
                     data-id={props.response_id} 
                     data-value='dislike' 
                     data-url={item.url}
                      className="table-dislike-img"
                      src={item.user_feedback === 'dislike' ? dislike_source : dislike_icon}
                      alt="dislike icon"
                    />{" "}
                    {item.dislikes > 0 ? (<span className="item-dislikes">{item.dislikes}</span>) : (<span className="item-dislikes"></span>)}
                  </td>
                  <td>
                  {userGroups == "Admin" && (
                    <div className="table-action">
                      <a
                        href="#"
                        className="action_btn_dots"
                        onClick={handleActionButtons}
                      ></a>
                      <div className={`action_btns 
                      ${item.tags && item.tags.includes("boosted") ? "show-unboosted" : ""}
                      ${item.tags && item.tags.includes("blocked") ? "show-unblocked" : ""}
                        `}>

                          <a
                            href="#"
                            className="action_btn_unboost"
                            onClick={handleActions}
                            data-action="unboost"
                            data-item-url={item.url}
                            data-ks-id={item.ks_id}
                          >
                            Un-boost
                          </a>
                            <a
                              href="#"
                              className="action_btn_boost"
                              onClick={handleActions}
                              data-action="boost"
                              data-item-url={item.url}
                              data-ks-id={item.ks_id}
                            >
                              Boost
                            </a>
                          <a
                            href="#"
                            className="action_btn_unblock"
                            onClick={handleActions}
                            data-action="unblock"
                            data-item-url={item.url}
                            data-ks-id={item.ks_id}
                          >
                            Un-block
                          </a>

                            <a
                              href="#"
                              className="action_btn_block"
                              onClick={handleActions}
                              data-action="block"
                              data-item-url={item.url}
                              data-ks-id={item.ks_id}
                            >
                              Block
                            </a>
                      </div>
                    </div>
                  )}
                  </td>
                </tr>
              ))}
            {/* If no items are found, display a message */}
            {items.length == 0 && (
              <tr>
                <td colSpan="7">
                  <div className="no-items">No sources found!</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="table-footer"></div>
      </div>
    </div>
  );
}

export default Sources;
