import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import MainLayout from '../layout/MainLayout';
import Dashboard from "../components/dashboard/Dashboard";

function MainContent(props) {
    const { isLoading, user, isAuthenticated, loginWithRedirect } = useAuth0();
    const [isMounted, setIsMounted] = useState(true); // Add a state variable to track if the component is mounted

    useEffect(() => {
        return () => {
            setIsMounted(false); // Set the component's mounted state to false when unmounting
        };
    }, []);

    if (isLoading) {
        // Optional: Show a loading indicator while checking the authentication state
        return (
            <div className="main-loader">
                <div className='chat-loading-wrap-app'>
                    <div className="chat-loader">
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </div>
                    <div className='loading-text'>Please wait...</div>
                </div>
            </div>
        );
    }

    if(!isAuthenticated) {
        loginWithRedirect();
    }

    if (!isMounted) {
        return null; // If the component is unmounted, return null to avoid rendering
    }

    return (
        <MainLayout>
            <Dashboard></Dashboard>
            {/* <div className="container">
                <div className="container">
                </div>
                <Selector />
            </div> */}
        </MainLayout>
    ); 
}

export default MainContent;
