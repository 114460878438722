import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { useForumList } from './common/ForumListContext';
import { clearLocalStorageItems } from './common/Functions';
import { useAuth0 } from "@auth0/auth0-react";
import { is } from "date-fns/locale";

function SideBarNav(props) {
    const { logout, user } = useAuth0();
    const location = useLocation();
     const { forumIds, selectedForumId, setSelectedForumId, setSelectForumName, selectForumName } = useForumList();
     const [isAdmin, setIsAdmin] = useState(false);

    const handleNavToggle = (e) => {
        e.preventDefault();
        props.setIsSidebarActive((prev) => !prev);
      };
      const handleSidebarNav = (e, forumId, forumName) => {
        e.preventDefault();
        setSelectedForumId(forumId);
        setSelectForumName(forumName);
      };
      useEffect(() => {
        // console.log('Updated selectedForumId:', selectedForumId);
        // Perform any additional actions here
      }, [selectedForumId]);
    useEffect(() => {
        if (user) {
            const roles = user?.ept_roles;
            if (roles && roles.includes('Admin')) {
                setIsAdmin(true);
            }
        }
      }, [user]);
      
  return (
    
    <div className="sidebar-nav">
        <div className="sidebar-inner">
            <a href="#" className="nav-toggle-btn" onClick={(e)=> handleNavToggle(e)}></a>
            <Link to="/" className="sidebar-logo">
                <span className="sidebar-logo-img"></span>
            </Link>
            
                <div className="nav-scroll">
                
                    <div className="upper-nav">
                        <div className="side-nav-heading">ept AI app channels</div>
                        <Link to="/" className={`ask-btn ${location.pathname === '/' ? 'active' : ''}`}>
                            <span>Ask a Question</span>
                        </Link>
                        {selectedForumId && forumIds.length > 0 && (
                            <>
                        <Link to={`/community-forum/${forumIds[0].value}/page/1`} className={`community-btn ${location.pathname.startsWith('/community-forum') ? 'active' : ''}`}>
                            <span>Community Forum</span>
                        </Link>
                        <ul className={`sidebar_forum_list ${location.pathname.startsWith('/community-forum') ? 'open' : ''}`}>
                            {forumIds && forumIds.map((forum) => (
                                <li key={forum.value}>
                                    <Link
                                        to={`/community-forum/${forum.value}/page/1`}
                                        className={`forum-item ${forum.value === Number(selectedForumId) ? 'active' : ''}`}
                                        // onClick={(e) => handleSidebarNav(e, forum.value, forum.label)}
                                    >
                                        <span>{forum.label.replace(/(http|https|s:|\/)/g, '')}</span>
                                    </Link>
                                </li>
                            )
                            )}
                        </ul>
                        </>
                    )}
                    
                    </div>
                
                
                    {isAdmin && (
                        
                        
                    <div className="upper-nav">
                        <div className="side-nav-heading">Channels & Knowledge</div>
                        <Link to="/settings/channels" className={`channel-btn ${location.pathname === '/settings/channels' ? 'active' : ''}`}>
                            <span>Channel Management</span>
                        </Link>
                        <Link to="/settings/knowledge-sources" className={`ks-btn ${location.pathname.startsWith('/settings/knowledge-sources') ? 'active' : ''}`}>
                            <span>Knowledge Sources</span>
                        </Link>
                        <Link to="/settings/knowledge-source-configurations" className={` ksc-btn ${location.pathname.startsWith('/settings/knowledge-source-configurations') ? 'active' : ''}`}>
                            <span>Knowledge Source Configurations</span>
                        </Link>
                    </div>
                        
                    )}
                    <div className="upper-nav">
                        <div className="side-nav-heading">AI Performance Management</div>
                        <Link to="/responses" className={`response-btn ${location.pathname === '/responses' ? 'active' : ''}`}>
                            <span>Responses</span>
                        </Link>
                        {isAdmin && (
                            <>
                        <Link to="/question-type" className={`question-btn ${location.pathname === '/question-type' ? 'active' : ''}`}>
                            <span>Question Type</span>
                        </Link>
                        <Link to="/ai-voice" className={`voice-btn ${location.pathname === '/voice' ? 'active' : ''}`}>
                            <span>AI Voice</span>
                        </Link>
                        <Link to="/reporting" className={`analysis-btn ${location.pathname === '/analysis' ? 'active' : ''}`}>
                            <span>Reporting</span>
                        </Link>
                        </>
                        )}
                    </div>
                        
                    
                    
                    <div className="upper-nav">
                        <div className="side-nav-heading">Account Settings</div>
                        {isAdmin && (
                            <>
                        <Link to="/settings/user-management" className={`user-btn ${location.pathname === '/settings/user-management' ? 'active' : ''}`}>
                            <span>Users</span>
                        </Link>
                        <Link to="/settings/billing" className={`billing-btn ${location.pathname === '/settings/billing' ? 'active' : ''}`}>
                            <span>Billing & Subscription </span>
                        </Link>
                        </>
                        )}
                        <a className="logout-btn" href="#" onClick={() => {
                      // check if itme exist in local storage
                      clearLocalStorageItems();
                      localStorage.removeItem('redirectPath');
                    logout({ logoutParams: { returnTo: window.location.origin } })
                    }}><span>Logout</span></a> 
                    </div>
                    
            </div>
            
            {/* <div className="lower-nav">
                <div className="nav-item">
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout</span>
                </div>  
            </div> */}
        </div>
    </div>
    
    );
}

export default SideBarNav;